body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.filter-big-buttons {
    margin: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background: #222;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px inset black;
    align-items: center;
}

.filter-big-buttons button {
    
}

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

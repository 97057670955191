#main-content {
	width: 100px;
	/*
		No idea why this works but if we don't set width,
		the body of the page will sometimes stretch if the content
		is too wide. But if we set the width to ANYTHING, even 0px,
		suddenly it will start working and will only stretch to the
		width of the page at max. Some weird flex magic.
		
		100px was chosen at random.
	*/
}

.ra-field {
    word-wrap: break-word;
}

.menu-header {
	margin-bottom: 10px;
	display: inline-block;
	font-weight: bold;
	transition: height 0.3s linear;
	height: 17px;
	overflow: hidden;
}

.MuiButtonBase-root[title="Dashboard"] ~ div > .menu-header {
	height: 0px;
	transition: height 0.3s linear;
}

pre.code {
	white-space: pre-wrap;
	background: rgba(0,0,0,0.2);
}

table.doc-desc td {
	padding: 5px 20px 5px 0px;
}

table.doc-desc > tr > td {
	padding: 5px 10px;
}

table.doc-desc > tr {
	margin: 3px 0px;
	background: rgba(0,0,0,0.2);	
}

table.doc-desc {
	border-left: 4px solid #00cfff;
	padding-left: 8px;
}

div.doc h2 {
	color: #00cfff;
}
div.doc h1 {
	background: #1a0549;
    display: inline-block;
    padding: 4px 16px;
    position: relative;
    left: -16px;
    border-radius: 0px 5px 5px 0px;
}

.RaSimpleShowLayout-stack>.ra-field>span,
.RaSimpleShowLayout-stack>.ra-field>pre,
.RaSimpleShowLayout-stack>.ra-field>div:not(.MuiGrid-container):not(.custom-iterator ),
.RaSimpleShowLayout-stack>.ra-field>a,
 .RaSimpleShowLayout-stack .field-value {
    border: 1px solid rgba(128,128,128,0.25);
    padding: 10px;
	margin: 0px;
    width: 100%;
    display: block;
    border-radius: 0px 5px 5px 5px;
    box-shadow: inset 2px 2px rgba(0,0,0,0.25);
    background: rgba(0,0,0,0.15);
	min-height: 46px;
}

.RaSimpleShowLayout-stack>.ra-field>pre {
	font-size: 0.875rem;
	word-break: break-word;
    overflow-wrap: anywhere;
    overflow: hidden;    
    white-space: break-spaces;
	line-break: anywhere;
}

.RaSimpleShowLayout-stack>.ra-field>p,
.RaSimpleShowLayout-stack .field-label {
    background: rgba(128,128,128,0.25);
    display: inline-block;
    padding: 4px 10px;
    margin-bottom: 0px;
    margin-top: 10px;
    border-radius: 5px 5px 0px 0px;
	color: rgba(255, 255, 255, 0.7);
	font-size: 0.75em;
}

.RaSimpleShowLayout-stack>.ra-field>p:has(+ .custom-iterator) {
	display: none;
}

.RaSimpleShowLayout-stack .field-value .RaChipField-chip {
	margin: 0px 4px;
}

.MuiChip-root {
    margin: 0px 4px;
}

.RaSimpleShowLayout-stack .big-value>span {

}

.RaSimpleShowLayout-stack .hide-sublabel>p {
	display: none;
}

.RaShow-main>.MuiPaper-root {
    padding-bottom: 10px;
}

.edit-page .ra-input,
.create-page .ra-input {
	width: 100%;
}

.edit-page .RaSimpleFormIterator-inline .ra-input:not(.MuiFormControl-fullWidth),
.create-page .RaSimpleFormIterator-inline .ra-input:not(.MuiFormControl-fullWidth) {
	width: unset;
}

.license-box {
	line-break: anywhere;
	font-family: monospace;
	background: #2196f3;
	display: block;
	padding: 10px;
	border: 1px solid white;
	border-radius: 5px;
	color: white;
	box-shadow: 0px 2px 10px rgba(0,0,0,0.5);
	position: relative;
	overflow: hidden;
}

.license-cool-icon {
    position: absolute;
    opacity: 0.15;
    transform: scale(5);
    top: 100px;
    left: 80px;
}

.license-tools {
	padding: 20px;
	text-align: center;
}

.license-tools button {
    margin: 0px 10px;
    padding: 10px 15px;
}

.license-edit-warning {
	background: #FE0;
    border: 3px solid;
    box-shadow: 0px 0px 5px;
    padding: 10px;
	overflow: hidden;
}
.license-edit-warning .warn-ico {
	display: inline-block;
    float: left;
    margin-right: 5px;
    transform: scale(5);
    width: 130px;
    height: 130px;
    transform-origin: top left;
}

.dashboard-card-bottom-text {
	vertical-align: top;
	display: inline-block;
	margin-top: 2px;
	margin-left: 5px;
}

.dashboard-card-bottom-panel {
	margin: 15px -25px -25px -25px;
	background: rgba(255,255,255,0.1);
	padding: 6px 25px 4px;
}

.dashboard-card-backicon {
    position: absolute;
    right: 0px;
    top: 0px;
    zoom: 5;
    z-index: -99;
    opacity: 0.05;
}

.pulsing-red {
  border-radius: 4px;
  box-shadow: 0 0 0 rgba(255,0,0, 0.4);
  animation: pulse-red 2s infinite;
}

@-webkit-keyframes pulse-red {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,0,0, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 20px rgba(255,0,0, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(255,0,0, 0);
  }
}
@keyframes pulse-red {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,0,0, 0.4);
    box-shadow: 0 0 0 0 rgba(255,0,0, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 20px rgba(255,0,0, 0);
      box-shadow: 0 0 0 20px rgba(255,0,0, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255,0,0, 0);
      box-shadow: 0 0 0 0 rgba(255,0,0, 0);
  }
}

span.event-log-row.flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

span.event-log-row.type-2 {
	color: #ff9800;
}

span.event-log-row.type-3,
span.event-log-row.type-4,
span.event-log-row.type-0 {
	color: #ef5350;
}

span.event-log-row:not(.flex) svg {
	display: inline-block;
    height: 16px;
    position: relative;
    top: 3px;
}

span.small-infobox {
	border: 1px solid rgba(255,255,255,0.25);
    padding: 20px;
    border-radius: 3px;
    border-left: 4px solid;
    margin: 20px 0px;
    background: rgba(0,0,0,0.25);
    box-shadow: 2px 2px 4px rgba(0,0,0,0.25);
	display: flex;
}

span.small-infobox svg {
	margin: 0px 15px 0px 10px;
}

.RaSimpleFormIterator-form,
.RaSimpleFormIterator-buttons {	
	width: 100%;
	padding: 20px;
    border: 1px solid rgba(255,255,255,0.2);
    margin: 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0,0,0, 0.5);
}

.RaSimpleFormIterator-line:hover>.RaSimpleFormIterator-form {
	background: rgba(255,255,255,0.05);
}
.MuiGrid-item.right-arrow {
	position: relative;
	z-index: 99;
}
.MuiGrid-item.right-arrow:after {
	content: "";
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
    right: -16px;
    top: 59px;
    border-top: 16px solid transparent;
	border-bottom: 16px solid transparent;  
	border-left: 16px solid rgba(128,128,128,0.25);
	z-index: -99;
}

.auto-option svg {
    width: 50px;
    height: 20px;
    position: relative;
    top: 3px;
}

.auto-editor-subform {
	width: calc(100% - 10px);
	margin-left: 10px;
	padding-left: 20px;
	margin-bottom: 0px;
	border-left: 1px solid;
	position: relative;
}

.auto-editor-subicon {
    position: absolute;
    top: 0px;
    left: -12px;
    width: 24px;
    height: 24px;
    display: inline-block;
    color: #222;
    background: #FFF;
    border-radius: 24px;
    text-align: center;
    line-height: 24px;
}
.auto-editor-subicon svg {
	margin-top: 2px;
}

table.auto-table-show td {
    border: 1px solid #888;
    padding: 0px 10px;
}

.auto-editor {
	width: 100%;
}

.auto-editor .RaSimpleFormIterator-action {
	width: 80px;
}

.auto-editor .RaSimpleFormIterator-form {
	padding: 10px;
}

.auto-editor .RaSimpleFormIterator-buttons {
	padding: 10px;
	width: calc(100% - 10px);
}

.auto-editor-subform .comment {
    background: rgba(255, 255, 0, 0.2);
    border-radius: 10px;
    box-shadow: 0px 0px 3px rgba(255, 255, 0, 1);
  }
  
.auto-editor-subform .comment p.MuiFormHelperText-filled,
.auto-editor-subform .comment p.MuiFormHelperText-root {
    display: none;
}

.auto-editor-subform .comment>label {
    display: none;
}
.auto-editor-subform .comment>div.MuiInputBase-root {
    padding: 10px;
    text-align: center;
}
.auto-editor-subform .comment>div.MuiInputBase-root textarea {
    text-align: center;
}
.auto-editor-subform .comment>div.MuiInputBase-root::before {
    display: none;
}